<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col cols="10" sm="8" md="6" lg="4">

            <!-- TODO: link to view account -->
            <!-- TODO: show featured products; allow organization to select which products are on front page, or set it up with special banners with a page builder, or something like that; and/or we can show links to view account, go to brand's homepage (link from their brandprofile), etc. we don't need to check if uesr is authenticated, because if they try to access a feature that is for authenticated users only they will get redirected to login page; -->
            <!-- TODO: the list of products needs to be styled better; if there's a product image then show the image and product name in a little card maybe -->
            <!-- TODO: show the cancel button differently, and make it clear that it empties the entire cart ... and not just goes back to last page... maybe there need to be separate buttons for these two things, properly labeled -->
            <!-- <template v-if="cart">
                <CheckoutProgress step="receipt" class="mb-6 no-print" :shipping="cart.require.require_shipping"/>
            </template> -->
            <BrandImage v-if="brandprofile" :brandprofile="brandprofile" intent="logotype" mode="light" :height="36" fit="inside" contain position="center" class="mb-6"/>
            <v-card elevation="2" v-if="isViewReady && serverError">
                <v-app-bar :color="primaryColor" dark flat dense>
                    <v-app-bar-title>An unexpected error occurred</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>Please contact customer support.</p>
                </v-card-text>
            </v-card>

            <template v-if="receipt && !isError">
            <v-switch v-model="showOrderItems" :color="primaryColor" dense label="Order details" class="no-print mx-4"></v-switch>
            <v-card>
                <v-app-bar flat dense>
                    <v-app-bar-title :color="primaryColor">Receipt</v-app-bar-title>
                    <v-spacer/>
                    <!-- <p class="ma-0 text-end"> -->
                    <span style="font-size: 0.7em;" class="text-end">Account ID {{ accountIdText }}<br>Order ID {{ orderIdText }}</span>
                    <!-- </p> -->
                </v-app-bar>
                <v-simple-table dense class="receipt-form">
                    <template #default>
                        <tbody>
                            <tr v-if="orderDate">
                                <th style="width: 25%;">Order Date</th>
                                <td style="width: 75%;">{{ orderDate }}</td>
                            </tr>
                            <tr v-if="paymentDate">
                                <th style="width: 25%;">Payment Date</th>
                                <td style="width: 75%;">{{ paymentDate }}</td>
                            </tr>
                            <tr>
                                <th>Amount Paid</th>
                                <td>
                                    {{ formatAmount(receipt.currency, receipt.amount_csu) }}
                                </td>
                            </tr>
                            <!-- <tr>
                                <th style="width: 25%;">Customer ID</th>
                                <td style="width: 75%;">{{ accountIdText }}</td>
                            </tr>
                            <tr>
                                <th style="width: 25%;">Order ID</th>
                                <td style="width: 75%;">{{ orderIdText }}</td>
                            </tr>
                            <tr>
                                <th style="width: 25%;">Payment ID</th>
                                <td style="width: 75%;">{{ paymentIdText }}</td>
                            </tr> -->
                            <tr v-if="receipt.payment_method">
                                <th style="width: 25%;">Charged to</th>
                                <td style="width: 75%;">{{ receipt.payment_method }}</td>
                            </tr>
                            <tr v-if="receipt.payment_descriptor">
                                <th style="width: 25%;">Will appear as</th>
                                <td style="width: 75%;">{{ receipt.payment_descriptor }}</td>
                            </tr>
                            <tr v-if="receipt.transaction_id">
                                <th style="width: 25%;">Transaction ID</th>
                                <td style="width: 75%;">{{ receipt.transaction_id }}</td>
                            </tr>
                            <tr v-if="receipt.name">
                                <th style="width: 25%;">Customer name</th>
                                <td style="width: 75%;">{{ receipt.name }}</td>
                            </tr>
                            <tr v-if="receipt.email">
                                <th style="width: 25%;">Customer email</th>
                                <td style="width: 75%;">{{ receipt.email }}</td>
                            </tr>
                            <tr v-if="receipt.billing_address">
                                <th style="width: 25%;">Billing address</th>
                                <td style="width: 75%;"><PostalAddress :address="receipt.billing_address"/></td>
                            </tr>
                            <tr v-if="receipt.shipping_address">
                                <th style="width: 25%;">Shipping address</th>
                                <td style="width: 75%;"><PostalAddress :address="receipt.shipping_address"/></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <!-- <v-row no-gutters class="receipt-header mt-3 mx-4">
                    <v-col cols="6">
                        <p class="mb-0 font-weight-bold text-start">Amount Paid</p>
                        <p class="text-start">
                            {{ formatAmount(receipt.currency, receipt.amount_csu) }}
                        </p>
                    </v-col>
                    <v-col cols="6">
                        <p class="mb-0 font-weight-bold text-start">Date Paid</p>
                        <p class="text-start">
                            {{ paymentDate }}
                        </p>
                    </v-col>
                </v-row> -->
                <!-- <v-card-text>
                </v-card-text> -->
                <v-simple-table v-if="showOrderItems">
                    <template #default>
                        <thead>
                            <tr>
                                <!-- <th style="text-align: start; width: 16px; vertical-align: middle;">
                                    <v-checkbox dense class="ma-0 checkout-item-selection"></v-checkbox>
                                </th> -->
                                <th style="text-align: start; width: 70%;">Item</th>
                                <th style="text-align: start; width: 150px;">Qty</th>
                                <th style="text-align: end;">Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, idx) in receipt.content" :key="'item__'+idx">
                                <!-- <td style="text-align: start; vertical-align: middle;">
                                    <v-checkbox dense class="ma-0 checkout-item-selection"></v-checkbox>
                                </td> -->
                                <td style="text-align: start;">
                                    <template v-if="item.is_service && item.type === 'saas'">
                                        <v-row>
                                            <v-col class="grow">
                                                <p class="mb-0">
                                                <!-- item.product_id -->
                                                {{ item.name }}
                                                <!-- {{ item.price_d }} -->
                                                </p>
                                            </v-col>
                                            <v-col class="shrink">
                                                <!-- <template v-if="serviceMap[item.product_id] && serviceMap[item.product_id].product && serviceMap[item.product_id].product.content && serviceMap[item.product_id].product.content.saas && serviceMap[item.product_id].product.content.saas.saas_entry_url"> -->
                                                <!-- after purchasing a service, the prominent next action should be to navigate to that service to start using it; we have a button for SSO and a button for regular redirect, these are mutually exclusive -->
                                                <template v-if="item.route && item.route.saas_sso_redirect">
                                                    <v-btn :to="item.route.saas_sso_redirect" :style="primaryButtonStyle" class="no-print" small>Sign in</v-btn>
                                                </template>
                                                <template v-else-if="item.link && item.link.saas_entry_url">
                                                    <v-btn :href="item.link.saas_entry_url" :style="primaryButtonStyle" class="no-print" small>Sign in</v-btn>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template v-else-if="item.is_download && item.type === 'download'">
                                        <v-row>
                                            <v-col class="grow">
                                                <p class="mb-0">
                                                <!-- item.product_id -->
                                                {{ item.name }}
                                                <!-- {{ item.price_d }} -->
                                                </p>
                                            </v-col>
                                            <v-col class="shrink">
                                                <template v-if="item.route && item.route.download">
                                                    <v-btn :to="item.route.download" :style="primaryButtonStyle" class="no-print" small>Download</v-btn>
                                                    <!-- <v-btn :href="item.link.download_url" :style="primaryButtonStyle" class="no-print">Download</v-btn> -->
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template v-else>
                                        <p class="mb-0">
                                        <!-- item.product_id -->
                                        {{ item.name }}
                                        <!-- {{ item.price_id }} -->
                                        </p>
                                    </template>
                                    <!-- <p class="mb-0 text-caption" v-if="item.is_custom_price">
                                        You decide the amount
                                    </p>
                                    <p class="mb-0 text-caption" v-if="item.is_recurring">
                                        <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width/>
                                        Recurring charge every {{ item.recurring_interval.count }} {{ item.recurring_interval.unit }}
                                    </p> -->
                                </td>
                                <td style="text-align: start;">
                                    <template v-if="item.is_custom_price">1</template>
                                    <template v-if="item.is_flat_price && !item.is_metered">1</template>
                                    <template v-if="item.is_metered">Metered</template>
                                    <template v-if="!item.is_flat_price && !item.is_metered && !item.is_custom_price">{{ item.quantity }}</template>
                                </td>
                                <td style="text-align: end;">
                                    <template v-if="item.is_metered">
                                        {{ formatAmount(item.currency, item.subtotal_csu) }}
                                    </template>
                                    <template v-if="!item.is_metered">
                                        {{ formatAmount(item.currency, item.subtotal_csu) }}
                                    </template>
                                    <template v-if="item.is_custom_price">
                                        {{ formatAmount(item.currency, item.subtotal_csu) }}
                                        <!-- TODO: just display the custom price; this isn't the place to edit it, but we can show a button to return to product page to edit the amount and then they can return to checkout from there -->
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <!-- TODO: shipping charges, taxes, and any other fees should be itemized items in the order so they show up in the above list -->
                <v-card-actions class="no-print" v-if="isAuthenticated">
                    <v-spacer/>
                    <span v-if="isOrderProcessed">Order has been processed&nbsp;|&nbsp;</span>
                    <span v-else-if="isOrderActivated">Order is being processed&nbsp;|&nbsp;</span>
                    <!-- <v-btn :style="primaryButtonStyle" @click="redirectAfterReceipt">Continue</v-btn> -->
                    <!-- <TextButton :color="primaryColor" @click="redirectToOrderStatus" underline>
                        <span v-if="isOrderActivated">Order is being processed</span>
                        <span v-if="!isOrderActivated">See order status</span>
                    </TextButton> -->
                    <TextButton :color="primaryColor" @click="redirectAfterReceipt" underline>Go to my account</TextButton>
                </v-card-actions>
            </v-card>
            </template>
            <v-row no-gutters v-if="receipt">
                <v-col cols="12">
                    <!-- TODO: if order requires signup, show link to continue to a page that says we sent an email with a link and they need to follow that link to complete the sign up process -->
                    <!-- TODO: if order does not require signup, show a link for optional signup , which will send out the email and redirect the user to same page to inform them to click on the link -->
                    <!-- TODO: if the order does not require signup, also show a "continue shopping" link... or maybe alwsays show it? -->
                    <!-- <router-link :to="frontLink" class="no-print">Continue shopping</router-link> -->
                    <p class="no-print text-center mt-2" v-if="isContinueShopping">
                    <TextButton :color="primaryColor" @click="continueShopping" underline>Continue shopping</TextButton> <!-- TODO: alternative text? "Buy something else" ? "Start over" ? ??? -->
                    </p>
                <!-- TODO: organization's contact information from brandprofile. for example:
                Brand Name (bold)

                Organization Name
                Main correspondence address / HQ address line1
                Main correspondence address / HQ address line2
                Main correspondence address / HQ address city, state, zip
                Main correspondence address / HQ address country
                Customer support URL
                Customer support phone #
                -->
                </v-col>
            </v-row>
            <!-- TODO:  if there's an error loading the cart, show temporary error: -->
            <v-card elevation="2" v-if="isViewReady && serverError">
                <v-app-bar color="red darken-2" dark flat dense>
                    <v-app-bar-title>Maintenance</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>The server is temporarily unavailable.</p>
                    <!-- TODO: We are going to automatically retry until it's ready. Please wait... -->
                    <!-- <p>Return to the last page and contact the emergency home for details.
                    <v-btn :to="loginRoute" elevation="2" :style="primaryButtonStyle" class="my-6" outlined>Sign in</v-btn>
                    <p>No account yet? <a :href="mainWebsiteURL">Sign up</a></p> -->
                    <!-- <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Unicorn Springs</a></p> -->
                </v-card-text>
            </v-card>
            <LoginCard :nextRoute="this.$router.currentRoute.fullPath" v-if="isViewReady && unauthorizedError"/>
            <v-card elevation="2" v-if="isViewReady && forbiddenError">
                <v-app-bar :color="primaryColor" dark flat dense>
                    <v-app-bar-title>Forbidden</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>You do not have permission to access this resource.</p>
                </v-card-text>
            </v-card>
            <!-- <template v-if="!isAuthenticatedReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="isAuthenticatedReady">
                <template v-if="isAuthenticated">
                    <v-row style="height: 100%" align="center" justify="center">
                        <div class="app-splash-loader"></div>
                        <p class="mt-6">
                        <router-link :to="{ name: 'dashboard' }">Continue to dashboard</router-link>
                        </p>
                    </v-row>
                </template>
                <template v-if="!isAuthenticated">
                    <LoginCard/>
                </template>
            </template> -->
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
.v-input .v-input__prepend-outer {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
/* remove hint area from item selection checkbox */
.v-input.v-input--checkbox.checkout-item-selection .v-messages {
    display: none;
}
/* adjust text size for receipt */
.receipt-header p {
    font-size: 0.8em;
}
/* align table headings and content to top */
.receipt-form th {
    vertical-align: top;
    padding-top: 6px !important;
    padding-bottom: 4px !important;
}
.receipt-form td {
    vertical-align: top;
    padding-top: 6px !important;
    padding-bottom: 4px !important;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
import BrandImage from '@/components/BrandImage.vue';
// import CheckoutProgress from '@/components/CheckoutProgress.vue';
import PostalAddress from '@/components/PostalAddress.vue';
import TextButton from '@/components/TextButton.vue';
import LoginCard from '@/components/LoginCard.vue';

function formatId(input) {
    const parts = [];
    let i = 0;
    while (i < input.length) {
        const part = input.substr(i, 5);
        parts.push(part);
        i += 5;
    }
    return parts.join('-');
}

export default {
    components: {
        BrandImage,
        // CheckoutProgress,
        PostalAddress,
        TextButton,
        LoginCard,
    },
    data: () => ({
        // cart: null,
        receipt: null,
        serviceMap: {},
        isActivated: null,
        showOrderItems: true,
        isViewReady: false,
        unauthorizedError: false,
        forbiddenError: false,
        serverError: false,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            brandNotFoundError: (state) => state.brandNotFoundError,
            // account: (state) => state.account,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        // paymentId() {
        //     if (!this.receipt?.paymentId) {
        //         return null;
        //     }
        //     return formatId(this.receipt.paymentId);
        // },
        // paymentIdText() {
        //     const paymentId = this.paymentId;
        //     if (!paymentId) {
        //         return null;
        //     }
        //     return `# ${paymentId}`;
        // },
        accountId() {
            if (!this.receipt?.accountId) {
                return null;
            }
            return formatId(this.receipt.accountId);
        },
        accountIdText() {
            const accountId = this.accountId;
            if (!accountId) {
                return null;
            }
            return `# ${accountId}`;
        },
        orderId() {
            if (!this.receipt?.orderId) {
                return null;
            }
            return formatId(this.receipt.orderId);
        },
        orderIdText() {
            const orderId = this.orderId;
            if (!orderId) {
                return null;
            }
            return `# ${orderId}`;
        },
        paymentDate() {
            if (!Number.isInteger(this.receipt?.payment_timestamp)) {
                return null;
            }
            const text = new Date(this.receipt.payment_timestamp).toISOString().split('T')[0];
            return text;
        },
        orderDate() {
            if (!Number.isInteger(this.receipt?.order_timestamp)) {
                return null;
            }
            const text = new Date(this.receipt.order_timestamp).toISOString().split('T')[0];
            return text;
        },
        isOrderProcessed() {
            return this.receipt?.order_status === 'processed';
        },
        isOrderActivated() {
            return this.receipt?.order_status === 'activated';
        },
        frontLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-front', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'front' };
            }
            return link;
        },
        /*
        orderLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-TBD-view-order', params: { brandprofile: this.brandprofile, orderId: this.receipt.orderId } };
            } else {
                link = { name: 'TBD-view-order' };
            }
            return link;
        },
        */
        isError() {
            return this.unauthorizedError || this.forbiddenError || this.serverError;
        },
        isDonation() {
            return this.receipt?.is_donation;
        },
        isService() {
            return this.receipt?.is_service;
        },
        isDownload() {
            return this.receipt?.is_download;
        },
        isContinueShopping() {
            // show the 'continue shopping' link under the card ONLY if the cart was not a service cart or a donation cart
            return !this.isDonation && !this.isService;
        },
    },
    watch: {
        isAuthenticatedReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.init();
            }
        },
        // isAuthenticated(newValue, oldValue) {
        //     if (newValue && !oldValue) {
        //         this.redirectAuthenticatedUser();
        //     }
        // },
        brandprofile(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.init();
            }
        },
        focus() {
            if (this.brandprofile) {
                this.init();
            }
        },
        receipt(newValue) {
            if (newValue?.order_status === 'paid') {
                this.activateOrder(); // harmless to activate twice or more, server will check status and only do it the first time
            }
        },
    },
    methods: {
        resetErrors() {
            this.unauthorizedError = false;
            this.forbiddenError = false;
            this.serverError = false;
        },
        init() {
            this.isViewReady = false;
            this.receipt = null;
            this.resetErrors();
            this.loadReceipt();
        },
        // redirectAuthenticatedUser() {
        //     // TODO: we need to check query parameters, if there's an organization id redirect to the dashboard for that organization, otherwise if there's only one organization, go to that one, or if there's more than one show the organization selection , and if the user doens't have any organizations then show a message that they need to contact the administrator to be added to an organization
        //     this.$router.replace({ name: 'dashboard' });
        // },
        async loadReceipt() {
            try {
                console.log(`loadReceipt: loading receipt for order ${JSON.stringify(this.$route.query.order_id)}`);
                this.$store.commit('loading', { loadReceipt: true });
                const query = {
                    payment_intent: this.$route.query.payment_intent,
                    order_id: this.$route.query.order_id,
                };
                const response = await this.$client.site(this.brandprofile).cart.receipt(query);
                if (response) {
                    if (Array.isArray(response.content)) {
                        for (let i = 0; i < response.content.length; i += 1) {
                            if (['saas', 'membership'].includes(response.content[i].type)) {
                                console.log(`loadReceipt: marking item ${i} as service because of type ${response.content[i].type}`);
                                response.content[i].is_service = true;
                            }
                            if (['download'].includes(response.content[i].type)) {
                                console.log(`loadReceipt: marking item ${i} as download because of type ${response.content[i].type}`);
                                response.content[i].is_download = true;
                            }
                        }
                    }
                    this.receipt = response;
                    this.serverError = false;
                    this.forbiddenError = false;
                    this.unauthorizedError = false;
                    this.loadAdditionalInfo();
                } else {
                    this.receipt = null;
                    this.serverError = true;
                }
            } catch (err) {
                console.error('loadReceipt failed', err);
                if (err.response?.status === 401) {
                    this.unauthorizedError = true;
                }
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
                if (err.response?.status >= 500) {
                    this.serverError = true;
                }
            } finally {
                this.$store.commit('loading', { loadReceipt: false });
                this.isViewReady = true;
            }
        },
        async loadAdditionalInfo() {
            // if any items are services, load the service information from the server
            this.receipt.content.filter((item) => item.is_service).forEach((item) => {
                console.log(`loadAdditionalInfo: loading account service for item type ${item.type} product id ${item.product_id}`);
                this.loadAccountService(item.product_id);
            });
            // if any items are downloads, prepare the download links
            this.receipt.content.filter((item) => item.is_download).forEach((item) => {
                console.log(`loadAdditionalInfo: preparing download link for item type ${item.type} product id ${item.product_id}`);
                this.getDownloadInfo(item.product_id);
            });
        },
        async loadAccountService(productId) {
            try {
                this.$store.commit('loading', { loadAccountService: true });
                const accountServiceResponse = await this.$client.site(this.brandprofile).account(this.receipt.accountId).accountService.search({ product_id: productId, include: 'product' }); // TODO: if there's more than one, we want them sorted in descending order of created/acivated date to find the most recent one the user just purchased, but the product id would be the same either way and that's what we need to load the product info
                if (Array.isArray(accountServiceResponse.list) && accountServiceResponse.list.length > 0) {
                    const accountService = accountServiceResponse.list[0];
                    this.$set(this.serviceMap, productId, accountService);

                    // if the service has an entry url the UI will show a button; make it easy here by checking
                    console.log(`loadAccountService: productId ${productId}`);
                    if (accountService.type === 'saas' && accountService.product.content.saas.saas_sso_app_tag && Array.isArray(this.receipt?.content)) {
                        for (let i = 0; i < this.receipt.content.length; i += 1) {
                            if (this.receipt.content[i].product_id === productId) {
                                this.$set(this.receipt.content[i], 'route', {
                                    // this route object will be used in a link or button like :to="saas_sso_redirect"
                                    saas_sso_redirect: {
                                        name: 'brand-sso-redirect-outbound',
                                        params: {
                                            brandprofile: this.$route.params.brandprofile,
                                        },
                                        query: {
                                            product_id: productId, // the server will look up the saas_sso_app_tag for this product and redirect the user appropriately
                                            account_id: this.receipt.accountId,
                                        },
                                    },
                                });
                            }
                        }
                    }
                    if (accountService.type === 'saas' && accountService.product.content.saas.saas_entry_url && Array.isArray(this.receipt?.content)) {
                        for (let i = 0; i < this.receipt.content.length; i += 1) {
                            if (this.receipt.content[i].product_id === productId) {
                                this.$set(this.receipt.content[i], 'link', {
                                    saas_entry_url: accountService.product.content.saas.saas_entry_url,
                                });
                            }
                        }
                    }
                }
            } catch (err) {
                console.error('loadAccountService failed', err);
                if (err.response?.status === 401) {
                    this.unauthorizedError = true;
                }
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
                if (err.response?.status >= 500) {
                    this.serverError = true;
                }
            } finally {
                this.$store.commit('loading', { loadAccountService: false });
            }
        },
        async getDownloadInfo(productId) {
            try {
                this.$store.commit('loading', { getDownloadInfo: true });
                // console.log(`getDownloadInfo: ${productId}`);
                for (let i = 0; i < this.receipt.content.length; i += 1) {
                    // console.log(`getDownloadInfo checking item idx ${i}`);
                    if (this.receipt.content[i].product_id === productId) {
                        // console.log(`getDownloadInfo checking receipt product id ${this.receipt.content[i].product_id} against product id ${productId}`);
                        this.$set(this.receipt.content[i], 'route', {
                            download: {
                                name: 'brand-download',
                                params: {
                                    brandprofile: this.$route.params.brandprofile,
                                },
                                query: {
                                    product_id: productId, // the server will look up the download URL for this product and redirect the user appropriately
                                    account_id: this.receipt.accountId,
                                    order_id: this.receipt.orderId,
                                },
                            },
                        });
                    }
                }
            } catch (err) {
                console.error('getDownloadInfo failed', err);
                if (err.response?.status === 401) {
                    this.unauthorizedError = true;
                }
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
                if (err.response?.status >= 500) {
                    this.serverError = true;
                }
            } finally {
                this.$store.commit('loading', { getDownloadInfo: false });
            }
        },
        async activateOrder() {
            try {
                this.$store.commit('loading', { activateOrder: true });
                const query = {
                    order_id: this.receipt?.orderId,
                };
                const response = await this.$client.site(this.brandprofile).cart.activate(query);
                this.$set(this.receipt, 'order_status', response?.status);
                this.loadAdditionalInfo();
            } catch (err) {
                console.error('activateOrder failed', err);
            } finally {
                this.$store.commit('loading', { activateOrder: false });
            }
        },
        formatAmount(currency, amount) {
            // return fromCurrencyAmountCSU(this.price.currency, amount).toStringWithCurrencySymbol();
            const price = fromCurrencyAmountCSU(currency, amount ?? 0).toNumber();
            const display = new Intl.NumberFormat('en-US', { // TODO: localization, if we know user's locale use that instead of en-US
                currency,
                style: 'currency',
            }).format(price);
            return display;
        },
        redirectAfterReceipt() {
            // take user to account overview
            this.$nav.push({ name: 'account-dashboard', params: { accountId: this.receipt.accountId } });
            /*
            if (this.$route.query.i) {
                // TODO: check if there's information here for us to take them back to another website, for example a return_url with a connect api request to subscribe to a product
            } else {
                // take user to account overview
                this.$nav.push({ name: 'account-dashboard', params: { accountId: this.accountId } });
            }
            */
        },
        // redirectToOrderStatus() {
        //     this.$nav.push(this.orderLink());
        // },
        continueShopping() {
            this.$nav.push(this.frontLink);
        },
    },
    mounted() {
        // if (this.hostname === window.location.hostname) {
        //     // special case for front page of main site, it's not a storefront
        // }
        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
        if (this.brandprofile) {
            this.init();
        }
    },
};
</script>
