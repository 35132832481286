<template>
    <span class="address">
        <template v-if="single">
            {{ oneline }}
            <span v-for="(part, idx) in parts" :key="idx">
                {{ part }}<template v-if="idx !== last">, </template>
            </span>
        </template>
        <template v-else>
            <span v-for="(part, idx) in parts" :key="idx">
                {{ part }}<template v-if="idx !== last"><br></template>
            </span>
        </template>
    </span>
</template>

<script>
export default {
    props: ['address', 'single'], // whether to show the address all one one line; default is to show on multiple lines
    computed: {
        parts() {
            // only return address parts with something filled in
            return ['name', 'line1', 'line2', 'line3', 'line4', 'line5', 'city', 'state', 'code', 'country'].filter((item) => this.address[item]).map((item) => this.address[item]);
        },
        last() {
            return this.parts.length - 1;
        },
    },
};
</script>
